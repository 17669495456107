export interface WorkTypes {
    [key: string]: any;
    id: number;
    title: string;
    description: string;
    value: string;
    icon: string;
    children?: WorkTypes[];
}

// commented childrens are waiting for correction

export const projectConcernsData: WorkTypes[] = [
    {
        id: 1,
        value: 'tertiary',
        title: 'Tertiaire',
        description: '',
        icon: 'BsBuildings',
        children: [
            {
                id: 11,
                value: 'thermal',
                title: 'Thermique',
                description: '',
                icon: 'BsThermometerHalf',
                children: [
                    {
                        id: 111,
                        value: 'BAT-TH-102',
                        title: 'BAT-TH-102',
                        description:
                            'Chaudière collective à haute performance énergétique',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 112,
                        value: 'BAT-TH-109',
                        title: 'BAT-TH-109',
                        description:
                            'Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 113,
                        value: 'BAT-TH-112',
                        title: 'BAT-TH-112',
                        description:
                            'Système de variation électronique de vitesse sur un moteur asynchrone',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 114,
                        value: 'BAT-TH-113',
                        title: 'BAT-TH-113',
                        description:
                            'Pompe à chaleur de type air/eau ou eau/eau',
                        icon: 'BsFileEarmarkText',
                    },

                    {
                        id: 115,
                        value: 'BAT-TH-116',
                        title: 'BAT-TH-116',
                        description:
                            'Système de gestion technique du bâtiment pour le chauffage, l’eau chaude sanitaire, le refroidissement/climatisation, l’éclairage et les auxiliaires',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 116,
                        value: 'BAT-TH-125',
                        title: 'BAT-TH-125',
                        description:
                            "Ventilation mécanique simple flux à débit d'air constant ou modulé",
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 117,
                        value: 'BAT-TH-126',
                        title: 'BAT-TH-126',
                        description:
                            'Ventilation mécanique double flux avec échangeur à débit d’air constant ou modulé',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 118,
                        value: 'BAT-TH-134',
                        title: 'BAT-TH-134',
                        description:
                            'Système de régulation sur un groupe de production de froid permettant d’avoir une haute pression flottante (France métropolitaine)',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 119,
                        value: 'BAT-TH-140',
                        title: 'BAT-TH-140',
                        description:
                            'Pompe à chaleur à absorption de type air/eau ou eau/eau',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 1101,
                        value: 'BAT-TH-141',
                        title: 'BAT-TH-141',
                        description:
                            'Pompe à chaleur à moteur gaz de type air/eau',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 1102,
                        value: 'BAT-TH-158',
                        title: 'BAT-TH-158',
                        description:
                            'Pompe à chaleur réversible de type air/air',
                        icon: 'BsFileEarmarkText',
                    },
                ],
            },
            {
                id: 12,
                value: 'equipment',
                title: 'Équipement',
                description: '',
                icon: 'BsFillGearFill',
                children: [
                    {
                        id: 121,
                        value: 'BAT-EQ-117',
                        title: 'BAT-EQ-117',
                        description:
                            'Installation frigorifique utilisant du CO2 subcritique ou transcritique',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                      id: 122,
                      value: 'BAT-EQ-123',
                      title: 'BAT-EQ-123',
                      description:
                          "Moto-variateur synchrone à aimants permanents ou à réluctance",
                      icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 123,
                        value: 'BAT-EQ-127',
                        title: 'BAT-EQ-127',
                        description:
                            "Luminaire d'éclairage général à modules LED",
                        icon: 'BsFileEarmarkText',
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        value: 'industry',
        title: 'Industrie',
        description: '',
        icon: 'BsBezier',
        children: [
            {
                id: 21,
                value: 'utility',
                title: 'Utilité',
                description: '',
                icon: 'BsTools',
                children: [
                    {
                        id: 211,
                        value: 'IND-UT-102',
                        title: 'IND-UT-102',
                        description:
                            'Système de variation électronique de vitesse sur un moteur asynchrone',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 212,
                        value: 'IND-UT-104',
                        title: 'IND-UT-104',
                        description:
                            'Économiseur sur les effluents gazeux d’une chaudière de production de vapeur',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 213,
                        value: 'IND-UT-105',
                        title: 'IND-UT-105',
                        description:
                            'Brûleur micro-modulant sur chaudière industrielle',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 214,
                        value: 'IND-UT-117',
                        title: 'IND-UT-117',
                        description:
                            'Système de récupération de chaleur sur un groupe de production de froid',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 215,
                        value: 'IND-UT-130',
                        title: 'IND-UT-130',
                        description:
                            'Condenseur sur les effluents gazeux d’une chaudière de production de vapeur',
                        icon: 'BsFileEarmarkText',
                    },
                    // {
                    //     id: 216,
                    //     value: 'IND-UT-136',
                    //     title: 'IND-UT-136',
                    //     description: 'Systèmes moto-régulés',
                    //     icon: 'BsFileEarmarkText',
                    // },
                ],
            },
            {
                id: 22,
                value: 'building',
                title: 'Bâtiment',
                description: '',
                icon: 'BsBuilding',
                children: [
                    {
                        id: 221,
                        value: 'IND-BA-116',
                        title: 'IND-BA-116',
                        description: 'Luminaires à modules LED',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 222,
                        value: 'IND-BA-110',
                        title: 'IND-BA-110',
                        description: 'Déstratificateur ou brasseur d’air',
                        icon: 'BsFileEarmarkText',
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        value: 'residential',
        title: 'Résidentiel',
        description: '',
        icon: 'BsHouseDoor',
        children: [
            {
                id: 31,
                value: 'thermal',
                title: 'Thermique',
                description: '',
                icon: 'BsThermometerHalf',
                children: [
                    {
                        id: 311,
                        value: 'BAR-TH-107',
                        title: 'BAR-TH-107',
                        description:
                            'Chaudière collective haute performance énergétique',
                        icon: 'BsFileEarmarkText',
                    },

                    {
                        id: 312,
                        value: 'BAR-TH-107-SE',
                        title: 'BAR-TH-107-SE',
                        description:
                            'Chaudière collective haute performance énergétique avec contrat assurant la conduite de l’installation',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 313,
                        value: 'BAR-TH-123',
                        title: 'BAR-TH-123',
                        description:
                            'Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 314,
                        value: 'BAR-TH-129',
                        title: 'BAR-TH-129',
                        description: 'Pompe à chaleur de type air/air',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 315,
                        value: 'BAR-TH-150',
                        title: 'BAR-TH-150',
                        description:
                            'Pompe à chaleur collective à absorption de type air/eau ou eau/eau',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 316,
                        value: 'BAR-TH-166',
                        title: 'BAR-TH-166',
                        description:
                            'Pompe à chaleur collective de type air/eau ou eau/eau',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 317,
                        value: 'BAR-TH-171',
                        title: 'BAR-TH-171',
                        description: 'Pompe à chaleur de type air/eau',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 318,
                        value: 'BAR-TH-172',
                        title: 'BAR-TH-172',
                        description:
                            'Pompe à chaleur de type eau/eau ou sol/eau',
                        icon: 'BsFileEarmarkText',
                    },
                ],
            },
        ],
    },
    {
        id: 4,
        value: 'network',
        title: 'Réseau',
        description: '',
        icon: 'BsThermometerSun',
        children: [
            {
                id: 41,
                value: 'lighting',
                title: 'Éclairage',
                description: '',
                icon: 'BsLightbulb',
                children: [
                    {
                        id: 411,
                        value: 'RES-EC-104',
                        title: 'RES-EC-104',
                        description: "Rénovation d'éclairage extérieur",
                        icon: 'BsFileEarmarkText',
                    },
                ],
            },
            {
                id: 42,
                value: 'heat',
                title: 'Chaleur',
                description: '',
                icon: 'BsThermometerHigh',
                children: [
                    {
                        id: 421,
                        value: 'RES-CH-103',
                        title: 'RES-CH-103',
                        description:
                            'Réhabilitation d’un poste de livraison de chaleur d’un bâtiment tertiaire',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 422,
                        value: 'RES-CH-104',
                        title: 'RES-CH-104',
                        description:
                            'Réhabilitation d’un poste de livraison de chaleur d’un bâtiment résidentiel',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 423,
                        value: 'RES-CH-105',
                        title: 'RES-CH-105',
                        description:
                            'Passage d’un réseau de chaleur en basse température',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 424,
                        value: 'RES-CH-106',
                        title: 'RES-CH-106',
                        description:
                            'Mise en place d’un calorifugeage des canalisations d’un réseau de chaleur enterré ou en caniveau dans tout ou partie du réseau primaire',
                        icon: 'BsFileEarmarkText',
                    },
                    {
                        id: 425,
                        value: 'RES-CH-108',
                        title: 'RES-CH-108',
                        description:
                            'Récupération de chaleur fatale pour valorisation vers un réseau de chaleur ou vers un tiers (France métropolitaine)',
                        icon: 'BsFileEarmarkText',
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        value: 'agriculture',
        title: 'Agriculture',
        description: '',
        icon: 'BsFlower3',
        children: [
            {
                id: 51,
                value: 'thermal',
                title: 'Thermique',
                description: '',
                icon: 'BsThermometerHalf',
                children: [
                    {
                        id: 511,
                        value: 'AGRI-TH-104',
                        title: 'AGRI-TH-104',
                        description:
                            'Système de récupération de chaleur sur un groupe de production de froid hors tank à lait',
                        icon: 'BsFileEarmarkText',
                    },
                ],
            },
        ],
    },
    {
        id: 6,
        value: 'other',
        title: 'Autre',
        description: '',
        icon: 'BsWrench',
    },
];

import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
function TypeInstallationREF81Z({label, name, onChange}: {label: string, name: NamePath, onChange?: (value: string) => void}) {
  return <Form.Item
            name={name}
            label={label}
            
            rules={[
                {
                required: true,
                message: "Veuillez renseigner le type d'installation"
                },
            ]}
            >
            <Select
                placeholder="Sélectionnez le type d'installation"
                allowClear
                style={{width: '620px'}}
                onChange={onChange} // Ajout de l'événement onChange
            >
                <Option value="0"><b>Cas 1 :</b> CO2 frigoporteur diphasique pour production de froid positif</Option>
                <Option value="1"><b>Cas 2 :</b> Froid négatif seul en cascade avec CO2 subcritique</Option>
                <Option value="2"><b>Cas 3 :</b> Froid positif et négatif ou positif seul ou négatif seul, installation au CO2 transcritique</Option>
            </Select>
        </Form.Item>
}
export default TypeInstallationREF81Z;
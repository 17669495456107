import React, { useEffect, useMemo } from 'react';
import { FC, useState } from 'react';

import { Spinner } from '@/components';
import { useProjectCalculate } from '@/hooks/new-project/useProjectCalculate';
import { useRegistrationRequests } from '@/hooks/registration-requests/useRegistrationRequests';
import { useGetAffiliationRequestByTag } from '@/hooks/simulator/useGetAffiliationByTag';
import { RootState } from '@/store/store';
import { CloseOutlined } from '@ant-design/icons';
import soneparLogo from '@assets/img/company-logos/sonepar.svg';
import LogoCameoEnergy from '@assets/img/logo-cameo-energy.png';
import { resetOperationsVolumes } from '@store/slices/new-project/operationsSlice';
import {
    Image as AntdImage,
    Button,
    Card,
    ConfigProvider,
    Divider,
    Flex,
    Form,
    Layout,
    Result,
    Space,
    Typography,
} from 'antd';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { filterForProjectCalculation } from '../new-project/utils';
import { FormSelector } from './FormSelector';
import { FormInscription } from './Formulaires simples/FormInscription';
import { MenuChoix } from './MenuChoix';
import PrimeCeeRange from './PrimeCeeRange';
import './index.css';
import { PriceRangeType, createRegisterRequestJson } from './utils/utils';

const { Title } = Typography;
const { Content, Footer, Header } = Layout;

const PublicSimulator: FC = () => {
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();
    const tag = searchParams.get('aff');

    const { isPending, data: affiliation } = useGetAffiliationRequestByTag(
        tag ?? ''
    );
    /* Couleurs thème */
    const primary_color = '#002766';
    const border_color = '#76B8DE';
    const subtitle_color = '#5E5E5E';
    const footer_color = '#1e2852';

    /* Dimensions */
    const largeur_bouton = '130px';
    const largeur_fost = '100%';
    const largeur_max_fost = '540px';
    const hauteur_bouton = '36px';
    const hauteur_fost = 'auto';
    const padding_bouton = '10px';
    const padding_fost = '5px 10px';
    const ombreButton = '0 1px 1px 0 grey';
    const ombreCard = '0 1px 2px 0 grey';

    /* Désactiver les boutons d'estimation et d'envoi si champs manquants */
    const allValues = Form.useWatch([], form);
    const ceeValues = Form.useWatch(['items'], form);
    const [estimable, setEstimable] = React.useState<boolean>(false);
    const [submittable, setSubmittable] = React.useState<boolean>(false);

    /* Appels API */
    const { calculateProjectValume, isPending: isCalculatePending } =
        useProjectCalculate();

    const {
        sendRegistrationRequest,
        isPending: isRegistrationPending,
        isError: isRegistrationError,
    } = useRegistrationRequests();

    const result = useSelector((state: RootState) => state.operationsReducer);
    const dispatch = useDispatch();

    /* Activer/désactiver les boutons submit si les champs ne sont pas validés */
    React.useEffect(() => {
        form.validateFields(['items'], { validateOnly: true, recursive: true }) //valide les champs items pour estimation
            .then(() => {
                if (
                    allValues.items?.every(
                        (item: { ChoixFost: { Fost: any } }) =>
                            item?.ChoixFost?.Fost
                    )
                ) {
                    setEstimable(true); //active le bouton estimer
                }
                form.validateFields({ validateOnly: true }) //valide tous les champs items+inscription pour soumission demande
                    .then(() => setSubmittable(true)) // active le bouton envoyer la demande
                    .catch(() => setSubmittable(false)); // si champs inscriptions non validés, désactive le bouton envoyer la demande
            })
            .catch(() => {
                setEstimable(false);
                setSubmittable(false);
            }); //si champs non validés, désactive les 2 boutons
    }, [form, allValues]);

    /* Mettre à jour le texte du bouton Estimer si les champs items changent */
    const [lastEstimation, setLastEstimation] = useState();
    const [updated, setUpdated] = useState(false);
    const notChanged = useMemo(
        () =>
            lastEstimation &&
            JSON.stringify(lastEstimation) === JSON.stringify(ceeValues),
        [ceeValues, lastEstimation]
    );

    React.useEffect(() => {
        if (lastEstimation) {
            setUpdated(true);
        }
    }, [ceeValues, lastEstimation]);

    /* Récupérer estimation */
    const simulateProjects = async () => {
        setUpdated(false);
        setLastEstimation(ceeValues);
        const { items: data } = form.getFieldsValue();

        const items = filterForProjectCalculation(data);
        await calculateProjectValume({
            items: items,
            signatureQuotationDate: new Date().getTime(),
        });
    };

    /* Afficher/masquer le formulaire de contact */
    const [inscription, setInscription] = useState(false);
    const onInscriptionClick = () => {
        setInscription(true);
    };

    /* Remettre à vide les champs du formulaire de contact s'il est fermé */
    const onCloseClick = () => {
        form.resetFields(['inscription']);
        setInscription(false);
    };

    /* Affichage de la page succès après soumission du formulaire  */
    const [masqueForm, setMasqueForm] = useState(false);
    const [masqueSucces, setMasqueSucces] = useState(true);
    const submitForm = async () => {
        const formValues = form.getFieldsValue();
        const { inscription, items } = formValues;
        const operations = filterForProjectCalculation(items);
        const registration = createRegisterRequestJson(
            inscription,
            operations,
            result.totalOperations,
            {
                minPrime: result.totalPrimeMin ?? 0,
                maxPrime: result.totalPrimeMax ?? 0,
                fixPrime: result.totalPrimeMedium ?? 0,
            },
            result.volumes,
            affiliation?.tag
        );
        try {
            await sendRegistrationRequest(registration);
            setMasqueSucces(false); // affiche la page de succès
            setMasqueForm(true); // masque le formulaire
            dispatch(resetOperationsVolumes());
        } catch (err) {
            console.log(err);
        }
    };

    /* Recommencer après page succès */
    const onRedo = () => {
        form.resetFields(); // vide les anciennes valeurs
        setInscription(false); // masque les formulaire de contact
        setMasqueForm(false); // affiche le formulaire
        setMasqueSucces(true); // masque la page de succès
    };

    useEffect(() => {
        if (isRegistrationError) {
            notification.error({
                message: 'Notification',
                description:
                    "Un problème s'est produit, veuillez réessayer plus tard.",
            });
        }
    }, [isRegistrationError]);

    useEffect(() => {
        return () => {
            dispatch(resetOperationsVolumes());
        };
    }, [dispatch]);

    const totalStyle: React.CSSProperties = {
        boxShadow: ombreCard,
        position: 'sticky',
        bottom: '20px',
        top: 50,
        zIndex: 999,
    };
    const buttonStyle: React.CSSProperties = {
        width: largeur_bouton,
        height: hauteur_bouton,
        paddingLeft: padding_bouton,
        boxShadow: ombreButton,
    };
    const fostStyle: React.CSSProperties = {
        width: largeur_fost,
        height: hauteur_fost,
        padding: padding_fost,
        maxWidth: largeur_max_fost,
        boxShadow: ombreButton,
    };

    return (
        <>
            {isPending ? (
                <Spinner />
            ) : (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: primary_color,
                            colorText: primary_color,
                        },
                        components: {
                            Select: {
                                optionActiveBg: border_color,
                                optionSelectedBg: primary_color,
                                optionSelectedColor: 'white',
                            },
                            Typography: {
                                colorText: '', //ne marche pas mais permet d'hériter du changement de couleur à la sélection
                                colorInfoHover: primary_color,
                                lineHeight: 1.2,
                            },
                            Form: {
                                itemMarginBottom: 12,
                                verticalLabelPadding: '0 0 0',
                            },
                            Layout: {
                                headerBg: 'white',
                                bodyBg: 'white',
                                headerPadding: 0,
                                siderBg: 'white',
                                footerBg: footer_color,
                                footerPadding: '12px 0',
                            },
                        },
                    }}
                >
                    <Layout style={{ minHeight: '100vh' }}>
                        {'  '}
                        {/* 100vh pour garder le footer toujours en bas */}
                        <Header
                            style={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1000,
                                boxShadow: '0 0 10px 5px lightgray',
                                height: 'auto',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                }}
                            >
                                <div className="content">
                                    <Flex style={{ gap: '20px' }}>
                                        <Space
                                            direction="horizontal"
                                            size="large"
                                        >
                                            <a href="https://www.cameo-energy.com/">
                                                <AntdImage
                                                    width={130}
                                                    src={LogoCameoEnergy}
                                                    preview={false}
                                                />
                                            </a>
                                        </Space>
                                        {affiliation?.logo &&
                                            (affiliation?.partnerUrl ? (
                                                <a
                                                    href={
                                                        affiliation.partnerUrl
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer" // For security
                                                >
                                                    <AntdImage
                                                        style={{
                                                            maxWidth: '100%',
                                                            width: 'auto',
                                                            height: '43px',
                                                        }}
                                                        src={
                                                            affiliation?.tag.includes(
                                                                'sonepar'
                                                            )
                                                                ? soneparLogo
                                                                : affiliation.logo
                                                        }
                                                        preview={false}
                                                    />
                                                </a>
                                            ) : (
                                                <AntdImage
                                                    style={{
                                                        maxWidth: '100%',
                                                        width: 'auto',
                                                        height: '43px',
                                                    }}
                                                    src={
                                                        affiliation?.tag.includes(
                                                            'sonepar'
                                                        )
                                                            ? soneparLogo
                                                            : affiliation.logo
                                                    }
                                                    preview={false}
                                                />
                                            ))}
                                    </Flex>
                                </div>
                            </div>
                        </Header>
                        <Content>
                            <Flex
                                justify="space-around"
                                align="center"
                                style={{ marginTop: '3rem' }}
                            >
                                <div className="content" hidden={masqueForm}>
                                    <Typography
                                        style={{ paddingBottom: '10px' }}
                                    >
                                        <Title
                                            level={3}
                                            style={{
                                                color: primary_color,
                                                margin: 0,
                                                fontSize:
                                                    'clamp(16px, 3vw, 24px)',
                                            }}
                                        >
                                            Simulateur de prime CEE
                                        </Title>
                                    </Typography>
                                    <Form
                                        initialValues={{ items: [{}] }}
                                        form={form}
                                        name="project-forms"
                                        id="form" //permet d'associer le bouton submit en dehors des balises Form
                                        onFinish={submitForm}
                                        layout="horizontal"
                                        scrollToFirstError={true} //autoscroll sur le 1er champ en erreur
                                        labelWrap={true}
                                        wrapperCol={{ flex: 1 }}
                                    >
                                        <Form.List name="items">
                                            {(fields, { add, remove }) => (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        rowGap: 16,
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    {fields.map(
                                                        (field, index) => (
                                                            <Card
                                                                size="small"
                                                                title={`${
                                                                    field.name +
                                                                    1
                                                                }${
                                                                    field.name +
                                                                        1 ===
                                                                    1
                                                                        ? 'ère'
                                                                        : 'ème'
                                                                } opération`}
                                                                key={field.key}
                                                                style={{
                                                                    boxShadow:
                                                                        ombreCard,
                                                                }}
                                                                extra={
                                                                    // field.name !== 0 &&
                                                                    <CloseOutlined
                                                                        onClick={() => {
                                                                            remove(
                                                                                field.name
                                                                            );
                                                                            simulateProjects();
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                <Flex>
                                                                    {/* Appel du Menu de sélection de la FOST  */}
                                                                    <Form.Item
                                                                        noStyle
                                                                        shouldUpdate
                                                                    >
                                                                        {({
                                                                            getFieldValue,
                                                                        }) =>
                                                                            getFieldValue(
                                                                                [
                                                                                    'items',
                                                                                    field.name,
                                                                                    'ChoixFost',
                                                                                    'Fost',
                                                                                ]
                                                                            ) ===
                                                                            undefined ? (
                                                                                <MenuChoix
                                                                                    field={
                                                                                        field
                                                                                    }
                                                                                    buttonStyle={
                                                                                        buttonStyle
                                                                                    }
                                                                                    fostStyle={
                                                                                        fostStyle
                                                                                    }
                                                                                />
                                                                            ) : null
                                                                        }
                                                                    </Form.Item>
                                                                    {/* Appel du formulaire de FOST en fonction du choix */}
                                                                    <Form.Item
                                                                        noStyle
                                                                        shouldUpdate
                                                                    >
                                                                        {({
                                                                            getFieldValue,
                                                                        }) =>
                                                                            getFieldValue(
                                                                                [
                                                                                    'items',
                                                                                    field.name,
                                                                                    'ChoixFost',
                                                                                    'Fost',
                                                                                ]
                                                                            ) !==
                                                                            undefined ? (
                                                                                <FormSelector
                                                                                    field={
                                                                                        field
                                                                                    }
                                                                                    fost={getFieldValue(
                                                                                        [
                                                                                            'items',
                                                                                            field.name,
                                                                                            'ChoixFost',
                                                                                            'Fost',
                                                                                        ]
                                                                                    )}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                    </Form.Item>
                                                                </Flex>

                                                                {/* Bandeau pour afficher la prime opération */}
                                                                <Divider
                                                                    orientation="left"
                                                                    orientationMargin={
                                                                        0
                                                                    }
                                                                    style={{
                                                                        color: primary_color,
                                                                        borderColor:
                                                                            border_color,
                                                                        marginTop:
                                                                            '12px',
                                                                        marginBottom:
                                                                            '12px',
                                                                    }}
                                                                ></Divider>
                                                                <Flex
                                                                    justify="space-between"
                                                                    align="center"
                                                                >
                                                                    <PrimeCeeRange
                                                                        priceRangeType={
                                                                            PriceRangeType.PUBLIC_DETAIL
                                                                        }
                                                                        minPrice={
                                                                            result.volumes?.find(
                                                                                (
                                                                                    volume
                                                                                ) =>
                                                                                    volume?.ficheIndex ===
                                                                                    index
                                                                            )
                                                                                ?.totalprimemin
                                                                        }
                                                                        maxPrice={
                                                                            result.volumes?.find(
                                                                                (
                                                                                    volume
                                                                                ) =>
                                                                                    volume?.ficheIndex ===
                                                                                    index
                                                                            )
                                                                                ?.totalprimemax
                                                                        }
                                                                        mediumPrice={
                                                                            result.volumes?.find(
                                                                                (
                                                                                    volume
                                                                                ) =>
                                                                                    volume?.ficheIndex ===
                                                                                    index
                                                                            )
                                                                                ?.totalprimemedium
                                                                        }
                                                                        isPublic={
                                                                            true
                                                                        }
                                                                    />
                                                                </Flex>
                                                            </Card>
                                                        )
                                                    )}

                                                    {/* Bouton d'ajout d'opération */}
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        block
                                                    >
                                                        + Ajouter une opération
                                                    </Button>

                                                    {/* Bouton submit */}
                                                    {!!fields.length && (
                                                        <Button
                                                            id="responsive-btn"
                                                            type="primary"
                                                            style={{
                                                                minWidth:
                                                                    '160px',
                                                            }}
                                                            onClick={
                                                                simulateProjects
                                                            }
                                                            disabled={
                                                                !estimable ||
                                                                isCalculatePending ||
                                                                notChanged
                                                            }
                                                        >
                                                            {!estimable ? (
                                                                <Typography.Text
                                                                    strong
                                                                >
                                                                    Formulaire
                                                                    incomplet
                                                                </Typography.Text>
                                                            ) : updated ? (
                                                                <Typography.Text
                                                                    strong
                                                                >
                                                                    Actualiser
                                                                    la prime CEE
                                                                </Typography.Text>
                                                            ) : (
                                                                <Typography.Text
                                                                    strong
                                                                >
                                                                    Estimer la
                                                                    prime CEE
                                                                </Typography.Text>
                                                            )}
                                                        </Button>
                                                    )}

                                                    {/* Carte pour afficher les totaux prime et volume */}
                                                    {!!fields.length && (
                                                        <Card
                                                            size="small"
                                                            style={totalStyle}
                                                        >
                                                            <Flex
                                                                justify="space-between"
                                                                align="center"
                                                            >
                                                                <PrimeCeeRange
                                                                    priceRangeType={
                                                                        PriceRangeType.PUBLIC_TOTAL
                                                                    }
                                                                    minPrice={
                                                                        result.totalPrimeMin
                                                                    }
                                                                    maxPrice={
                                                                        result.totalPrimeMax
                                                                    }
                                                                    mediumPrice={
                                                                        result.totalPrimeMedium
                                                                    }
                                                                    isPublic={
                                                                        true
                                                                    }
                                                                />
                                                            </Flex>
                                                        </Card>
                                                    )}
                                                </div>
                                            )}
                                        </Form.List>

                                        <div
                                            style={{
                                                marginTop: 16,
                                                display: 'flex',
                                                rowGap: 16,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {/* Phrase et bouton pour afficher le formulaire de contact */}
                                            <Form.Item noStyle shouldUpdate>
                                                {inscription === false ? (
                                                    <>
                                                        <Flex
                                                            justify="space-evenly"
                                                            style={{
                                                                marginTop:
                                                                    '24px',
                                                                marginBottom:
                                                                    '24px',
                                                            }}
                                                        >
                                                            <Space
                                                                direction="vertical"
                                                                align="center"
                                                                size="middle"
                                                            >
                                                                <Typography>
                                                                    <Typography.Text
                                                                        strong
                                                                        style={{
                                                                            color: subtitle_color,
                                                                            fontSize:
                                                                                '1.5em',
                                                                        }}
                                                                    >
                                                                        Vous
                                                                        souhaitez
                                                                        bénéficier
                                                                        de cette
                                                                        prime ?
                                                                    </Typography.Text>
                                                                </Typography>
                                                                <Button
                                                                    type="primary"
                                                                    onClick={
                                                                        onInscriptionClick
                                                                    }
                                                                    disabled={
                                                                        !result.totalOperations
                                                                    }
                                                                >
                                                                    <Typography.Text
                                                                        strong
                                                                    >
                                                                        Cliquez
                                                                        ici
                                                                    </Typography.Text>
                                                                </Button>
                                                                <Typography>
                                                                    <Typography.Text
                                                                        style={{
                                                                            color: primary_color,
                                                                        }}
                                                                    >
                                                                        Vous
                                                                        serez
                                                                        recontacté
                                                                        par l'un
                                                                        des nos
                                                                        experts
                                                                        CEE.
                                                                    </Typography.Text>
                                                                </Typography>
                                                            </Space>
                                                        </Flex>
                                                    </>
                                                ) : null}
                                            </Form.Item>

                                            {/* Affichage du formulaire de contact */}
                                            <Form.Item noStyle shouldUpdate>
                                                {inscription === true ? (
                                                    <>
                                                        <Card
                                                            size="small"
                                                            style={{
                                                                boxShadow:
                                                                    ombreCard,
                                                            }}
                                                        >
                                                            <FormInscription
                                                                onClick={
                                                                    onCloseClick
                                                                }
                                                            />
                                                        </Card>
                                                        <Flex justify="end">
                                                            <Button
                                                                type="primary"
                                                                style={{
                                                                    alignSelf:
                                                                        'flex-end',
                                                                    width: '30%',
                                                                    minWidth:
                                                                        '175px',
                                                                }}
                                                                htmlType="submit"
                                                                loading={
                                                                    isRegistrationPending
                                                                }
                                                                disabled={
                                                                    !submittable
                                                                }
                                                            >
                                                                <Typography.Text
                                                                    strong
                                                                >
                                                                    Soumettre le
                                                                    projet
                                                                </Typography.Text>
                                                            </Button>
                                                        </Flex>
                                                    </>
                                                ) : null}
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </div>
                            </Flex>

                            {/* Page de succès après soumission */}
                            <Flex
                                justify="space-around"
                                align="center"
                                style={{ marginTop: '10px' }}
                            >
                                <div hidden={masqueSucces}>
                                    <Result
                                        status="success"
                                        title="Merci, votre demande nous a bien été transmise."
                                        subTitle="Vous serez prochainement recontacté par l'un de nos experts CEE."
                                        extra={[
                                            <Button
                                                key="1"
                                                type="primary"
                                                href="https://www.cameo-energy.com"
                                            >
                                                <Typography.Text strong>
                                                    Retourner à l'accueil
                                                </Typography.Text>
                                            </Button>,
                                            <Button
                                                key="2"
                                                style={{
                                                    boxShadow: ombreButton,
                                                }}
                                                onClick={onRedo}
                                            >
                                                <Typography.Text strong>
                                                    Faire une nouvelle
                                                    simulation
                                                </Typography.Text>
                                            </Button>,
                                        ]}
                                    />
                                </div>
                            </Flex>
                        </Content>
                        {/* Bas de page  */}
                        <Footer>
                            <Flex justify="space-around">
                                <div className="content">
                                    <Flex justify="space-between" flex={1}>
                                        <Typography.Text
                                            style={{ color: 'white' }}
                                        >
                                            © 2024 Cameo
                                        </Typography.Text>
                                        <a
                                            href="https://www.cameo-energy.com/mentions-legales/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Typography.Text
                                                style={{ color: 'white' }}
                                            >
                                                Mentions Légales
                                            </Typography.Text>
                                        </a>
                                    </Flex>
                                </div>
                            </Flex>
                        </Footer>
                    </Layout>
                </ConfigProvider>
            )}
        </>
    );
};
export default PublicSimulator;

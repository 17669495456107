import { Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';

const { Option } = Select;
export default function InstallationIREF104B({label, name}: {label: string, name: NamePath}) {
  return <Form.Item
            name={name}
            label={label}
            rules={[
                {
                required: true,
                message: "Veuillez renseigner l'option"
                },
            ]}
            >
            <Select
                placeholder="Sélectionnez l'option"
                allowClear
                style={{width: '550px'}}
            >
                <Option value="0"><b>Option 0 :</b> Sans alimentation des évaporateurs en régime saturé</Option>
                <Option value="1"><b>Option 0 :</b> Avec alimentation des évaporateurs en régime saturé (option 3)</Option>
                <Option value="2"><b>Option 1 ou 1 <i>bis</i> :</b> Sans alimentation des évaporateurs en régime saturé</Option>
                <Option value="3"><b>Option 1 ou 1 <i>bis</i> :</b> Avec alimentation des évaporateurs en régime saturé (option 3)</Option>
                <Option value="4"><b>Option 2 :</b> Sans alimentation des évaporateurs en régime saturé</Option>
                <Option value="5"><b>Option 2 :</b> Avec alimentation des évaporateurs en régime saturé (option 3)</Option>
            </Select>
        </Form.Item>
}
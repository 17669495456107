import { Form, Alert, Typography, Select, Flex, Divider, Button } from 'antd';
import { useState } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FilePdfOutlined } from '@ant-design/icons';
import PuissanceREF26 from '../refs/PuissanceREF26';
import TypeInstallationREF81Z from '../refs/TypeInstallationREF81Z';
import InstallationIREF104B from '../refs/InstallationIREF104B';

const { Paragraph, Title } = Typography;
const { Option } = Select;

export const Bateq1172S = ({ field }: { field: any }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAT-EQ-117";
    const libelle_fost = "Installation frigorifique utilisant du CO2 subcritique ou transcritique";
    const denomination_fost = <>Remplacement d’une installation frigorifique centralisée utilisant un
    hydrofluorocarbure (HFC) par une installation neuve centralisée utilisant le CO2 comme fluide frigoporteur
    diphasique ou frigorigène ou mise en place d’une installation frigorifique neuve utilisant le CO2 comme fluide
    frigorigène de puissance frigorifique inférieure à 40 kW.</>;
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/documents/BAT-EQ-117%20vA40-2%20%C3%A0%20compter%20du%2001-04-2022.pdf";

    // Gestion du changement de valeur dans TypeInstallationREF81Z
    const handleTypeInstallationChange = (value: string | number) => {
        form.setFieldsValue({
            ['items']: { [field.name]: { "IREF-104-B": undefined } }  // Remise à zéro du champ InstallationIREF104B
        });
    };

    return (
        <>
            {/* En-tête du formulaire */}
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Contenu du formulaire */}

            <Form.Item noStyle shouldUpdate>
                <>
                {/* Référence : Type d'installation */}
                <TypeInstallationREF81Z
                    label="Sélectionnez le cas correspondant à votre situation"
                    name={[field.name, 'REF-81-Z']}
                    onChange={handleTypeInstallationChange}  // Gestionnaire de changement
                />

                {/* Référence : Puissance Frigorifique */}
                <PuissanceREF26
                    label="Entrez la Puissance frigorifique"
                    name={[field.name, 'REF-26']}
                    unite={'KW'}
                    tooltip={
                        <>
                            <div>
                                <p>
                                    Si vous êtes dans le <b>Cas 1</b> : entrez la Puissance frigorifique utile délivrée par les compresseurs positives.
                                </p>
                                <p>
                                    Si vous êtes dans le <b>Cas 2</b> : entrez la Puissance frigorifique utile délivrée par les compresseurs négatives.
                                </p>
                                <p>
                                    Si vous êtes dans le <b>Cas 3</b> : la somme des puissances frigorifiques à retenir est la somme de la puissance frigorifique
                                    positive utile délivrée par les compresseurs neufs au CO2 positifs (moyenne température) et de la puissance frigorifique
                                    négative utile délivrée par les compresseurs neufs au CO2 négatifs (basse température). Les valeurs figurent sur le document
                                    issu de l’installateur frigoriste.
                                </p>
                            </div>
                        </>
                    }
                />
                {/* Suite formulaire si Cas 3 */ }
                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                        getFieldValue(['items', field.name, "REF-81-Z"]) >= 2 ? (
                            <>
                                {/* Nb d'appartements */}
                                <InstallationIREF104B
                                    name={[field.name, "IREF-104-B"]}
                                    label="Installation"
                                />

                            </>
                        ) : null
                    }
                </Form.Item>
                </>
            </Form.Item>
        </>
    );
};

import { Form, Alert, FormListFieldData, Radio, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import ApplicationMoteurREF40I from '@/features/new-project/operations/forms/components/common/ApplicationMoteurREF40I';
import PuissanceREF26 from '@/features/new-project/operations/forms/components/common/PuissanceREF26';

const { Title, Paragraph, Text } = Typography

export const Bateq1232 = ({ field }: { field: FormListFieldData }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAT-EQ-123";
    const libelle_fost = "Moto-variateur synchrone à aimants permanents ou à réluctance";
    const denomination_fost = <>Mise en place d’un moto-variateur synchrone à aimants permanents ou à réluctance de puissance nominale inférieure ou égale à 1 MW.</>;
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/documents/BAT-EQ-123%20v%20A25-2%20apr%C3%A8s%20le%2001-04-2017.pdf";



    /* Textes explicatifs */
    const eligibilite = (
        <div>
            <Text>Pour être éligible, assurez-vous que votre installation respecte les critères suivants :</Text>
            <Paragraph>
                - Machine synchrone à aimants permanents ou à réluctance<br />
                - Puissance utile nominale inférieure ou égale à 1 MW
            </Paragraph>
        </div>
    );

    const facture = (
        <div>
            <Text>Pour être éligible, assurez-vous que votre installation respecte les critères suivants :</Text>
            <Paragraph>
                La preuve de réalisation de l’opération doit mentionner la mise en place d’un moto-variateur synchrone à aimants permanents ou à réluctance et sa puissance électrique nominale.
            </Paragraph>
        </div>
    );

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    return (
        <>
            {/* En-tête du formulaire */}
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Contenu du formulaire */}

            {/* Bâtiment tertiaire */}
            <Form.Item
                name={[field.name, "BatimentTertiaire"]}
                label="L'opération est-elle réalisée sur un bâtiment tertiaire ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "REF-26"], undefined)
                    form.setFieldValue(['items', field.name, "REF-40-I"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si batiment tertiaire non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire"
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>
            {/* Suite formulaire si Batiment OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire"]) === "Oui" ? (
                        <>
                            {/* Application */}
                            <ApplicationMoteurREF40I
                            label="Application du moteur électrique sur lequel est installé votre moto-variateur synchrone à aimants permanents"
                            name={[field.name, 'REF-40-I']}
                            />
                            <PuissanceREF26
                                name={[field.name, "REF-26"]}
                                label="Puissance utile nominale du moto-variateur synchrone à aimants permanents (kW) :"
                                unite='kW'
                                tooltip={(
                                    <>
                                    La puissance nominale à retenir est celle figurant sur la plaque signalétique du moto-variateur
                                    ou à défaut celle indiquée sur un document issu du fabricant.
                                    Lorsque l’opération concerne la mise en place de plusieurs moto-variateurs synchrones à aimants permanents ou à
                                    reluctance de caractéristiques identiques, la puissance nominale à prendre en compte dans le calcul est la somme,
                                    par type d’application, des puissances nominales de chaque équipement.
                                    </>
                                )}
                            />
                        </>
                    ) : null
                }
            </Form.Item>
            {/* Alerte en cas de puissance inférieure à 1000 kW */}
            <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            const puissance = getFieldValue(['items', field.name, "REF-26"]);
                            return Number(puissance) > 1000 ? (
                                <Alert
                                    message="L'opération n'est éligible que pour une puissance nominale inferieur ou égale à 1000 kW = 1MW"
                                    type="error"
                                    showIcon
                                />
                            ) : null;
                        }}
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button>
                    : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};

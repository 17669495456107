import { Form, Alert, FormListFieldData, Radio, Typography, Flex, Divider, Button, Collapse, Card } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import PuissanceREF26 from '@/features/new-project/operations/forms/components/common/PuissanceREF26';
import TypeInstallationREF81Z from '@/features/new-project/operations/forms/components/common/TypeInstallationREF81Z';
import InstallationIREF104B from '@/features/new-project/operations/forms/components/common/InstallationIREF104B';

const { Title, Paragraph, Text } = Typography

export const Bateq1172 = ({ field }: { field: any }) => {

    const form = useFormInstance()

    /* Couleurs */
    const primary_color = '#002766'
    const border_color = '#76B8DE'
    const subtitle_color = '#5E5E5E'
    const tooltip_color = '#0086CA'

    /* Infos FOST */
    const code_fost = "BAT-EQ-117";
    const libelle_fost = "Installation frigorifique utilisant du CO2 subcritique ou transcritique";
    const denomination_fost = <>Remplacement d’une installation frigorifique centralisée utilisant un
    hydrofluorocarbure (HFC) par une installation neuve centralisée utilisant le CO2 comme fluide frigoporteur
    diphasique ou frigorigène ou mise en place d’une installation frigorifique neuve utilisant le CO2 comme fluide
    frigorigène de puissance frigorifique inférieure à 40 kW.</>;
    const lien_pdf = "https://www.ecologie.gouv.fr/sites/default/files/documents/BAT-EQ-117%20vA40-2%20%C3%A0%20compter%20du%2001-04-2022.pdf";

    /* Textes explicatifs */
    const eligibilite = (
        <Typography>
        Cette fiche s’applique au remplacement d’une installation frigorifique centralisée
        utilisant un hydrofluorocarbure (HFC) par une installation neuve centralisée utilisant
        le CO2 comme fluide frigoporteur diphasique ou frigorigène. Trois cas d’opérations éligibles
        sont détaillées dans le tableau ci-dessous, en fonction du type d’installations existantes
        et du type d’installations neuves mises en place en remplacement de l’installation existante (cf. colonne « Solution éligible »).
        Se référer au tableau des cas présent dans le descriptif de la fiche d’opération <a href="https://www.ecologie.gouv.fr/sites/default/files/documents/BAT-EQ-117%20vA40-2%20%C3%A0%20compter%20du%2001-04-2022.pdf">BAT-EQ-117</a> (Voir tableau des cas).
    </Typography>
    );

    const facture = (
<Paragraph>
        La mise en place de l’installation frigorifique au CO2 fait l’objet d’un rapport daté et signé par un professionnel (installateur, bureau d’études,…).
        <li>Ce rapport mentionne :</li>
        <ul>
            <li>La raison sociale et l’adresse du bénéficiaire ;</li>
            <li>L’adresse du chantier si différente de l’adresse du bénéficiaire ;</li>
            <li>Les températures des besoins en froid positif et négatif de l’installation ; une justification des valeurs des températures au regard de l’utilisation faite du froid (par exemple : « Tnég = -30°C pour meubles frigorifiques de vente négatifs ») ;</li>
            <li>Les puissances des besoins en froid positif et négatif de l’installation ; une justification des valeurs des puissances au regard de l’utilisation faite du froid (par exemple : « Pfnég = 50 kWf pour X ml de meubles frigorifiques de vente négatifs ») ;</li>
            <li>Les caractéristiques du groupe existant de production de froid : nature des fluides frigorigènes, puissances installées des compresseurs positifs et négatifs, architecture (cascade, circuits séparés, détente directe, nature du frigoporteur…) ;</li>
            <li>Les marque et référence du groupe de production de froid neuf installé (mono-compresseur ou multicompresseurs) ;</li>
            <li>La puissance frigorifique utile positive totale en kW délivrée par les compresseurs positifs neufs, la puissance frigorifique utile négative totale en kW délivrée par les compresseurs négatifs CO2 neufs, ainsi que le point de fonctionnement (températures négative, positive et extérieure) auquel ont été déterminées ces puissances. Ce point de fonctionnement doit être cohérent avec les températures du besoin en froid de l’installation et la zone climatique dans laquelle se situe l’opération ;</li>
            <li>Le cas retenu parmi les cas 1, 2 et 3 et, si le cas 3 est retenu, l’option retenue parmi les options 0, 1, 1 bis, 2 et 3, tels que mentionnés dans le tableau de la partie 2 de la présente fiche ;</li>
            <li>dans les cas 1 et 2, le fluide utilisé pour l’étage positif (le CO2 n’est pas éligible) ;</li>
            <li>Le cas échéant, les marques et références des éjecteurs ;</li>
            <li>Le cas échéant, le fait que l’installation comporte un système permettant l’alimentation des évaporateurs CO2 positifs en régime saturé, et les marques et références des bouteilles principale et secondaire utilisées. Le document justificatif spécifique à l’opération est le rapport susmentionné.</li>
        </ul>
    </Paragraph>
    );

    /* Constantes Tabs informations */
    const tabListNoTitle = [
        { key: '1', label: "Critères d'éligibilité" },
        { key: '2', label: 'Contenu de la facture' },
    ];
    const contentListNoTitle: Record<string, React.ReactNode> = {
        1: <>{eligibilite}</>,
        2: <>{facture}</>,
    };
    const [activeTabKey, setActiveTabKey] = useState<string>('1');
    const onTabChange = (key: string) => { setActiveTabKey(key) };

    // Gestion du changement de valeur dans TypeInstallationREF81Z
    const handleTypeInstallationChange = (value: string | number) => {
        form.setFieldsValue({
            ['items']: { [field.name]: { "IREF-104-B": undefined } }  // Remise à zéro du champ InstallationIREF104B
        });
    };

    return (
        <>
            {/* En-tête du formulaire */}
            <Title level={3} style={{ color: primary_color }}>{code_fost} : {libelle_fost}</Title>
            <Flex justify='space-between' align="center">
                <Paragraph strong style={{ color: subtitle_color, fontSize: '1.1em', margin: '0px' }}>
                    {denomination_fost}
                </Paragraph>
                <Button style={{ color: primary_color }} size='small' href={lien_pdf} target="_blank"><FilePdfOutlined />Fiche PDF</Button>
            </Flex>
            <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color }}>Formulaire de chiffrage</Divider>

            {/* Contenu du formulaire */}

            {/* Bâtiment tertiaire */}
            <Form.Item
                name={[field.name, "BatimentTertiaire2ans"]}
                label="L'opération est-elle réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans ?"
                rules={[
                    { required: true, message: "Veuillez renseigner ce champ" },
                ]}
            >
                <Radio.Group buttonStyle='solid' onChange={() => {
                    form.setFieldValue(['items', field.name, "REF-26"], undefined)
                    form.setFieldValue(['items', field.name, "REF-81-Z"], undefined)
                    form.setFieldValue(['items', field.name, "IREF-104-B"], undefined)
                }}>
                    <Radio.Button value="Oui">Oui</Radio.Button>
                    <Radio.Button value="Non">Non</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* Alerte si batiment tertiaire 2 ans non */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2ans"]) === "Non" ? (
                        <Alert
                            message="Cette opération n'est éligible que si elle est réalisée sur un bâtiment tertiaire existant depuis plus de 2 ans."
                            type="error"
                            showIcon
                        />
                    ) : null
                }
            </Form.Item>

            {/* Suite formulaire si batiment tertiaire 2 ans OK */}
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) =>
                    getFieldValue(['items', field.name, "BatimentTertiaire2ans"]) === "Oui" ? (
                    <>
                        {/* Référence : Type d'installation */}
                        <TypeInstallationREF81Z
                            label="Sélectionnez le cas correspondant à votre situation"
                            name={[field.name, 'REF-81-Z']}
                            onChange={handleTypeInstallationChange}  // Gestionnaire de changement
                        />

                        {/* Référence : Puissance Frigorifique */}
                        <PuissanceREF26
                            label="Entrez la Puissance frigorifique"
                            name={[field.name, 'REF-26']}
                            unite={'KW'}
                            tooltip={
                                <>
                                    <div>
                                        <p>
                                            Si vous êtes dans le <b>Cas 1</b> : entrez la Puissance frigorifique utile délivrée par les compresseurs positives.
                                        </p>
                                        <p>
                                            Si vous êtes dans le <b>Cas 2</b> : entrez la Puissance frigorifique utile délivrée par les compresseurs négatives.
                                        </p>
                                        <p>
                                            Si vous êtes dans le <b>Cas 3</b> : la somme des puissances frigorifiques à retenir est la somme de la puissance frigorifique
                                            positive utile délivrée par les compresseurs neufs au CO2 positifs (moyenne température) et de la puissance frigorifique
                                            négative utile délivrée par les compresseurs neufs au CO2 négatifs (basse température). Les valeurs figurent sur le document
                                            issu de l’installateur frigoriste.
                                        </p>
                                    </div>
                                </>
                            }
                        />
                        {/* Suite formulaire si Cas 3 */ }
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                getFieldValue(['items', field.name, "REF-81-Z"]) >= 2 ? (
                                    <>
                                        {/* Choix de l'installation */}
                                        <InstallationIREF104B
                                            name={[field.name, "IREF-104-B"]}
                                            label="Installation"
                                        />

                                    </>
                                ) : null
                            }
                        </Form.Item>
                    </>
               ) : null
            }
            </Form.Item>

            {/* Conseils CAMEO */}
            <Collapse
                bordered={false}
                style={{ color: primary_color, margin: '0px', padding: '0px', backgroundColor: 'white' }}
                size='small'
                collapsible='icon'
                expandIcon={
                    ({ isActive }) => isActive ?
                        <Button style={{ color: primary_color }} size='small'>Masquer</Button>
                    : <Button style={{ color: primary_color }} size='small'>Afficher</Button>}
                expandIconPosition='end'
                items={[{
                    key: '1',
                    label: <Divider orientation='left' orientationMargin={0} style={{ color: primary_color, borderColor: border_color, margin: '0px' }}>Conseils Cameo</Divider>,
                    children:
                        <Card tabList={tabListNoTitle} activeTabKey={activeTabKey} onTabChange={onTabChange} size='small'>
                            {contentListNoTitle[activeTabKey]}
                        </Card>,
                }]}
            />
        </>
    );
};
